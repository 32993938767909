<!-- 新增功能权限 -->
<template>
  <div class="editFunctional">
    <!-- 上面部分 -->
    <div class="top">
      <span style="font-size: 16px; font-weight: bold;">新增功能权限</span>
      <el-button class="el-btn" size="small" @click="goBack">
        返回
      </el-button>
    </div>
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      class="form"
      label-width="100px"
    >
      <el-form-item label="角色名称" prop="userName">
        <el-input
          v-model="ruleForm.userName"
          placeholder="请输入角色名称"
        ></el-input>
      </el-form-item>
    </el-form>
    <el-divider></el-divider>
    <el-tree
      :data="filterNav"
      show-checkbox
      node-key="label"
      ref="tree"
      highlight-current
      :props="defaultProps"
      :accordion="true"
      :default-checked-keys="allocation"
    >
      <span class="custom-tree-node" slot-scope="{ node, data }">
        <span>{{ node.label }}</span>
      </span>
    </el-tree>

    <el-divider></el-divider>

    <div slot="footer" class="dialog-footer">
      <div>
        <el-button class="btn" style="" @click="submit('ruleForm')">
          提 交
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import nav from "../../utils/surface.js";
export default {
  data() {
    return {
      ruleForm: {
        userName: "",
      },
      rules: {
        userName: [
          {
            required: true,
            message: "角色名称不能为空",
            trigger: "blur",
          },
        ],
      },
      nav, //权限列表
      defaultProps: {
        children: "children",
        label: "label",
      },
      allocation: [], //配置
      bool: false,
    };
  },
  created() {
    this.nav = this.deepCopy(this.nav);
    // this.nav.shift();
  },
  computed: {
    filterNav() {
      return this.nav.map((item, index) => {
        let newItem = this.deepCopy(item);
        // console.log(newItem, "tree tree");
        newItem.children = newItem.children.filter(
          (children) => children.noCache
        );

        return newItem;
      });
    },
  },
  methods: {
    goBack() {
      //返回
      this.$router.go(-1);
    },
    deepCopy(obj) {
      return JSON.parse(JSON.stringify(obj));
    },
    submit(form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          if (this.bool) {
            return;
          }
          this.bool = true;
          // 子元素
          const childrens = this.$refs.tree
            .getCheckedNodes()
            .filter((item) => Reflect.has(item, "groupid"));

          let arr = nav
            .map((item) => {
              let items = this.deepCopy(item);
              items.children = childrens.filter((childrenItem) => {
                if (childrenItem.groupid.startsWith(String(items.id))) {
                  return true;
                } else {
                  return false;
                }
              });
              return items;
            })
            .filter((item) => item.children.length);

          // console.log(arr);
          this.allocation = arr;
          this.$request({
            url: "/api/user/power_save",
            method: "POST",
            data: {
              name: this.ruleForm.userName,
              power: this.allocation,
            },
          })
            .then((res) => {
              if (res.code == 1) {
                this.$message({
                  message: "新增成功",
                  type: "success",
                });
                setTimeout(() => {
                  this.goBack();
                }, 1500);
              } else {
                this.$message({
                  message: res.msg,
                  type: "error",
                });
                setTimeout(() => {
                  this.goBack();
                }, 1500);
              }
            })
            .catch(() => {
              this.bool = false;
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style scoped="scoped">
.editFunctional {
  width: 100%;
  height: 100%;
}

/* 上面部分 */
.top {
  padding-top: 25px;
  margin: 0px 24px 39px 28px;
  display: flex;
  justify-content: space-between;
}

.el-btn {
  background: #18bc9c;
  padding-left: 25px;
  background-position: 5px 7px;
  display: inline-block;
  background-image: url(../../assets/back.png);
  background-repeat: no-repeat;
  color: #ffffff;
}

.el-form {
  margin-bottom: 20px;
}

.form .el-input {
  width: 276px;
  height: 37px;
}

.form ::v-deep .el-input__inner {
  width: 276px;
  height: 37px;
}

.form ::v-deep .el-form-item__label {
  color: #000000;
}

/* 权限列表 */

::v-deep .el-tree-node__content {
  font-size: 15px;
  font-weight: 600;
  line-height: 25px;
  color: #000;
}

::v-deep .el-tree-node__children .el-tree-node__content {
  color: #000;
  font-weight: 400;
}

.el-tree {
  width: 500px;
  margin-left: 22px;
  /* margin: 0px auto; */
}

/* 底部提交 取消 */
.dialog-footer {
  text-align: center;
  margin-top: 50px;
}

.btn {
  background: #18bc9c;
  color: #ffffff;
  width: 100px;
  font-size: 16px;
}
</style>
